<template>
    <div>
     
     <div class="hero-section lg:flex pb-5 align-middle">
         
         <div class="left-div lg:w-2/4 lg:mt-6 mt-10">
             <h1 class="lg:text-4xl text-2xl font-semibold lg:mt-16 lg:mr-10">Hi, I am Full Stack Web Developer.</h1>
             <p class="my-16 font-semibold">let me introduce myself my name is pappu saha. I,m  passionate Web Developer from Gazipur,Dhaka, Bangladesh. I am full stick web developer. Programming is one the most part in my life because I,m programming lover. Now I am working as a Full-stack Web Developer I can develop highly dynamic user friendly website with responsive using the best technologies & design trends.</p>
             
             <a href="https://drive.google.com/drive/folders/1vb_qkRbnDgpEbh5U101PRO4HLxpOJx3O?usp=sharing" target="_blank" class=" text-white bg-primary-color lg:px-20 px-10 py-3 text-lg font-semibold">GET MY CV</a>

         </div>

         <div class="right-div lg:mt-0 mt-20  items-end lg:ml-40">

             <div class="image">
                 <img src="./../../../assets/images/hero-image-cartoon.png" alt="" srcset="" class="object-contain men-image">
             </div>
         </div>
     </div>
          
         <div class="mt-20 experience bg-white pt-5 pb-10 shadow rounded border-l-8 border-color">
             <div class="content ">
                 <div class="title text-center">
                     <h1 class="pb-5 text-2xl border-b-4 border-gray-100 font-semibold">My Experience OverView</h1>
                 </div>

                 <div class="mt-10 lg:py-3 grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 items-center text-center gap-10 tex-white justify-center project px-10">
                   
                   <div class="item bg-primary-color rounded shadow py-8  px-16">
                       <h4 class="font-semibold">50+</h4>
                       <p class="font-semibold">Wordpress website</p>
                   </div>
                   <div class="item bg-primary-color rounded shadow py-8 px-14">
                       <h4 class="font-semibold">05</h4>
                       <p class="font-semibold">Custom website</p>
                   </div>
                   <div class="item bg-primary-color rounded shadow py-8 px-14">
                       <h4 class="font-semibold">04</h4>
                       <p class="font-semibold">Awesome Clients</p>
                   </div>
                   <div class="item bg-primary-color rounded shadow py-8 px-14">
                       <h4 class="font-semibold">13140</h4>
                       <p class="font-semibold">Hours Of Coding</p>
                   </div>
                   
                 </div>
             </div>
         </div>

         <div v-if="projects" class="mt-16 experience bg-white pt-5 pb-10 shadow rounded border-l-8 border-color">
             <div class="content ">
                 <div class="title text-center">
                     <h1 class="pb-5 text-2xl border-b-4 border-gray-100 font-semibold">Recent Projects</h1>
                 </div>

                 <div class="mt-10 text-black lg:py-3 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-center text-center gap-10 tex-white justify-center project px-10">
                   
                   <div v-for="(project, index) in projects" :key="index" class="text-black">
                      
                      <div class="shadow-xl pb-3 border border-gray-300 rounded-md">
                            
                             <div class="img relative overflow-hidden">
                                 <a :href="project.project_url" target="_blank">
                                  <img :src="url+project.image" alt="" srcset="">
                                </a>
                                <div class="ribbon">
                                    <h4 class="py-2 bg-green-600 rounded text-sm px-8 text-white">{{project.category.name | strToUpper}}</h4>
                                </div>
                             </div>
                          
                            <div class="category mt-3">
                                <a :href="project.project_url" target="_blank" class="p-2 block bg-green-600 rounded mx-3 text-white">{{project.name | strToUpper}}</a>
                            </div>
                      </div>

                   </div>
                   
                   
                 </div>
             </div>
         </div>
         

    </div>
</template>
<script>
import axios from 'axios'
    export default {
        metaInfo: {
        title: 'Home',
        },
        data() {
            return {
                projects:'',

            }

        },

        mounted(){
            axios.get('projects').then(res =>{
                 this.projects = res.data
            })
        }

    }
</script>


<style scoped>
.men-image{
   width: 520px;
}
.project{
    color: white;
}

.ribbon{
       position: absolute;
    top: 38px;
    left: -42px;
    transform: rotate(315deg);
    left: 5;
    width: 200px;
}
</style>
